import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { User, UserProfile } from '../../_models';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HandleErrorNotification } from '../../_helpers/handle-error-notification';
import { Observable } from 'rxjs';
import { UserFavoritesInterfaceData } from '../../_interfaces/user-favorites';
import { PlaylistsInterface } from '../../_interfaces/playlists.interface';
import { FindAllSequlizeReturnType, UserFindAll } from 'src/app/_interfaces/user-find-all';
import { Slick } from 'src/app/_models/slick';
import { HandleObject } from '../../shared/share/share.component';
import { UserPermission } from '../../_models/user-permission';
import { Permission } from '../../_enums/permission';
import { Handle } from '../../_models/handle';
import { Skill, SkillData } from '../../_models/skill';
import { Teleprompter } from '../../_interfaces/teleprompter';
import { UserCameraEffectsConfig } from '../../_interfaces/user-camera-effects-config';

//Gambiarras temporárias
interface searchSlick {
  slick: Array<Slick>;
  tag: Array<Slick>;
  topic: Array<Slick>;
  users: Array<Slick>;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private serverUrl = environment.serverUrl;

  constructor(
    private http: HttpClient,
    private handleErrorNotification: HandleErrorNotification
  ) {}

  search(params): Observable<searchSlick> {
    return this.http
      .get<searchSlick>(`${this.serverUrl}v1/users/all`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findAll(params): Observable<FindAllSequlizeReturnType<User>> {
    return this.http
      .get<UserFindAll>(`${this.serverUrl}v1/users`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getOrganisationUsers(organisationId: number): Observable<User[]> {
    return this.http
      .get<User[]>(`${this.serverUrl}v1/users/organisation/${organisationId}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findAllHandles(): Observable<HandleObject[]> {
    return this.http
      .get<UserFindAll>(`${this.serverUrl}v1/users/organisation/handles`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getCountryList(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.serverUrl}v1/users/countries`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getHandle(): Observable<Handle> {
    return this.http
      .get<Handle>(`${this.serverUrl}v1/users/handle`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getUser(): Observable<User> {
    return this.http
      .get<User>(`${this.serverUrl}v1/users/me`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  setTeleprompterConfiguration(config: Teleprompter): Observable<number> {
    return this.http
      .post<Teleprompter>(`${this.serverUrl}v1/users/settings/teleprompter`, config)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  setEffectsConfiguration(config: UserCameraEffectsConfig): Observable<User> {
    return this.http
      .post<UserCameraEffectsConfig>(`${this.serverUrl}v1/users/settings/camera-effects`, config)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getLikes(): Observable<any> {
    return this.http
      .get<any>(`${this.serverUrl}v1/users/likes`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getFavorites(params): Observable<UserFavoritesInterfaceData> {
    return this.http
      .get<UserFavoritesInterfaceData>(`${this.serverUrl}v1/users/favorites`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getPlaylist(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.serverUrl}v1/users/playlists/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getPlaylistSlick(params: { id: number; searchPage?: number; searchLimit?: number }): Observable<any> {
    return this.http
      .get<any>(`${this.serverUrl}v1/users/playlists-slick/${params.id}`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getPlaylists(params?): Observable<PlaylistsInterface[]> {
    return this.http.get<PlaylistsInterface[]>(`${this.serverUrl}v1/users/playlists`, { params });
  }

  deletePlaylist(id: number): Observable<any> {
    return this.http
      .delete<any>(`${this.serverUrl}v1/users/playlist/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  create(body): Observable<User> {
    return this.http
      .post<User>(`${this.serverUrl}v1/users`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  checkIfHandleIsAvalable(handle: string): Observable<any> {
    return this.http
      .get(`${this.serverUrl}v1/users/checkhandle`, { params: { handle } })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  selectTags(body): Observable<any> {
    return this.http
      .post<any>(`${this.serverUrl}v1/users/select/tag`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  selectTopics(body): Observable<any> {
    return this.http
      .post<any>(`${this.serverUrl}v1/users/select/topic`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  selectSlickFavorites(body): Observable<string> {
    return this.http
      .post<string>(`${this.serverUrl}v1/users/select/slick/favorite`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  updateFavorites() {
    return this.http;
  }

  createPlaylist(body): Observable<any> {
    return this.http
      .post<any>(`${this.serverUrl}v1/users/playlist`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  updatePlaylist(id: number, body): Observable<any> {
    return this.http
      .put<any>(`${this.serverUrl}v1/users/playlist/${id}`, body, { observe: 'response' })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  update(id: number, body): Observable<User> {
    return this.http
      .put<User>(`${this.serverUrl}v1/users/${id}`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  remove(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.serverUrl}v1/users/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  password(body: object): Observable<void> {
    return this.http
      .put<void>(`${this.serverUrl}v1/users/password`, body, { observe: 'response' })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  validatePassword(password: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.serverUrl}v1/users/validate/password?password=${password}`);
  }

  createPermission(body: UserPermission): Observable<UserPermission> {
    return this.http
      .post<any>(`${this.serverUrl}v1/users/permissions`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getPermissions(params: any): Observable<any> {
    return this.http
      .get<any>(`${this.serverUrl}v1/users/permissions`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  deletePermission(organisation_id: number, user_id: number, permission: Permission): Observable<any> {
    return this.http
      .delete<any>(`${this.serverUrl}v1/users/permissions/${organisation_id}/${user_id}/${permission}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  createUsersBulk(formData: FormData, params?: { orgId?: number; sendEmail?: boolean }): Observable<number> {
    return this.http
      .post<any>(`${this.serverUrl}v1/users/bulk-create`, formData, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  sendResetPasswordEmail(userId: number): Observable<unknown> {
    return this.http
      .post<any>(`${this.serverUrl}v1/users/resend-reset-password-email`, null, { params: { userId } })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getUserProfile(id: string): Observable<UserProfile> {
    return this.http
      .get<User>(`${this.serverUrl}v1/users/profile/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getSkills(): Observable<SkillData> {
    return this.http
      .get<SkillData>(`${this.serverUrl}v1/users/skill`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  addSkill(body): Observable<Skill[]> {
    return this.http
      .put<Skill[]>(`${this.serverUrl}v1/users/skill`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  removeSkill(id: number): Observable<User> {
    return this.http
      .delete<User>(`${this.serverUrl}v1/users/skill/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }
}
