import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService, UsersService, ScrollService, LoadFeedService } from './_services';
import { ToastService } from './_services';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthGuard } from './_guards/auth.guard';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
// import {MatIconModule} from '@angular/material/icon';
// import {MatToolbarModule} from '@angular/material/toolbar';
// import {MatInputModule} from '@angular/material/input';
// import {MatButtonModule} from '@angular/material/button';
// import {MatMenuModule} from '@angular/material/menu';
// import {MatSelectModule} from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormBaseComponent } from './_helpers/form-base.component';
//Register web components from swiper element
import { register } from 'swiper/element/bundle';
import { TextFieldModule } from '@angular/cdk/text-field';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import * as Sentry from '@sentry/angular';
import { TestModule } from './test/test.module';

register();

export function appInit(authService: AuthenticationService): () => Promise<void> {
  return () => authService.authenticate();
}

@NgModule({
  declarations: [AppComponent, FormBaseComponent],
  bootstrap: [AppComponent],
  imports: [
    AuthenticationComponent,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    // MatInputModule,
    ReactiveFormsModule,
    // MatToolbarModule,
    AuthenticationModule,
    RouterModule,
    // MatButtonModule,
    // MatIconModule,
    // MatMenuModule,
    // MatSelectModule,
    MatSnackBarModule,
    SharedModule,
    TextFieldModule,
    SweetAlert2Module.forRoot(),
    TestModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    AuthGuard,
    AuthenticationService,
    //Just here to have one singleton for whole application
    ScrollService,
    UsersService,
    ToastService,
    LoadFeedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [AuthenticationService],
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        scrollStrategy: new NoopScrollStrategy(),
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
