import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HandleErrorNotification } from '../../_helpers/handle-error-notification';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SlickData } from '../../_interfaces/slick.interface';
import { MySlickInterfaceData } from '../../_interfaces/my-slick.interface';
import { Slick } from 'src/app/_models/slick';
import { Visibility } from '../../_enums/visibility';
export interface CreateSlickDto {
  title: string;
  videoUrl: string;
  description: string;
  externalLink?: string;
  thumbnail?: string;
  tags_ids: number[];
  topics_ids: number[];
  startAt?: number;
  endAt?: number;
  visibility: Visibility;
  public_date?: string;
  private_date?: string;
  related_requests?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class SlicksService {
  private serverUrl = environment.serverUrl;

  constructor(
    private http: HttpClient,
    private handleErrorNotification: HandleErrorNotification
  ) {}

  create(body: CreateSlickDto): Observable<Slick> {
    return this.http
      .post<Slick>(`${this.serverUrl}v1/slicks`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getOne(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.serverUrl}v1/slicks/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  like(id: string, body: any): Observable<any> {
    return this.http
      .post<any>(`${this.serverUrl}v1/slicks/like/${id}`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findAll(params): Observable<SlickData> {
    return this.http
      .get<SlickData>(`${this.serverUrl}v1/slicks`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findAllAdminFeed(params): Observable<SlickData> {
    return this.http
      .get<SlickData>(`${this.serverUrl}v1/slicks/admin-feed`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findTopics(params): Observable<SlickData> {
    return this.http
      .get<SlickData>(`${this.serverUrl}v1/slicks?` + params)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  getTopics(params): Observable<SlickData> {
    return this.http
      .get<SlickData>(`${this.serverUrl}v1/topics?` + params)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findAllSupportSlicks(params): Observable<SlickData> {
    return this.http
      .get<SlickData>(`${this.serverUrl}v1/slicks/support`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findByUser(id: number): Observable<SlickData> {
    return this.http
      .get<SlickData>(`${this.serverUrl}v1/slicks/user/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findByUserOrganisation(id: number): Observable<Slick[]> {
    return this.http
      .get<Slick[]>(`${this.serverUrl}v1/slicks/organisation/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findMY(params): Observable<MySlickInterfaceData> {
    return this.http
      .get<MySlickInterfaceData>(`${this.serverUrl}v1/slicks/my`, { params })
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  findOne(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.serverUrl}v1/slicks/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  update(id: string, body: any): Observable<any> {
    return this.http
      .put<any>(`${this.serverUrl}v1/slicks/${id}`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${this.serverUrl}v1/slicks/${id}`)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  review(body: any): Observable<any> {
    return this.http
      .post<any>(`${this.serverUrl}v1/slicks/review/teleprompter`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }

  generatePrompt(body: { teleprompter: string; intent: string }): Observable<string[]> {
    return this.http
      .post<any>(`${this.serverUrl}v1/slicks/generate-teleprompter`, body)
      .pipe(catchError((err) => this.handleErrorNotification.ErrorNotification(err)));
  }
}
