import { Injectable } from '@angular/core';
import { Slick, User } from '../_models';
import { Role } from '../_enums/roles';
//thirds
import { parseISO, formatDistanceToNow } from 'date-fns';
import { Permission } from '../_enums/permission';
import { UserPermissionInterface } from '../_interfaces/permissions.interface';
import { Visibility } from '../_enums/visibility';
import { SlicksService } from './api/slicks.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private slicksService: SlicksService) {}

  //needed because angular can't find encodeURIComponent in the component.ts context
  encodeComponent(text: string) {
    return encodeURIComponent(text);
  }
  decodeComponent(text: string) {
    return decodeURIComponent(text);
  }

  dateFormat(date: Date | string) {
    return new Date(date).toLocaleDateString('en-ie', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  dateFormatUS(date: Date | string) {
    return new Date(date).toLocaleDateString('en-us', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }

  formatDate(date: Date | string) {
    return formatDistanceToNow(parseISO(date as string), { addSuffix: true });
  }

  //usage:
  //await sleep(3000);
  sleep = (m) => new Promise((r) => setTimeout(r, m));

  beep(duration: number, type: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const ctxClass = window.audioContext || window.AudioContext || window.AudioContext || window.webkitAudioContext;
    const ctx = new ctxClass();
    duration = +duration;
    // Only 0-4 are valid types.
    type = type % 5 || 0;
    const osc = ctx.createOscillator();
    osc.type = type;
    //osc.type = "sine";
    osc.connect(ctx.destination);
    if (osc.noteOn) {
      osc.noteOn(0);
    }
    if (osc.start) {
      osc.start();
    }
    setTimeout(function () {
      if (osc.noteOff) {
        osc.noteOff(0);
      }
      if (osc.stop) {
        osc.stop();
      }
    }, duration);
  }

  /**
   * from gemini
   * @param func function to run
   * @param delay time between events, eg: typing
   * @returns function
   *
   * Usage samples:
   *
   * this.searchForm.valueChanges.subscribe(this.debouncedSearch);
   * debouncedSearch = this.utilSvc.debounce((value: string) => {
   *   console.log('Searching for:', value);
   * }, 300);
   *
   */
  debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const funcName = func?.name || 'anonymous';

    return function (this: any, ...args: Parameters<T>) {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        func?.(...args) ?? console.warn(`Debounced function "${funcName}" is not defined`);
        timer = null;
      }, delay);
    };
  }

  /**
   * Get last position from an url
   */
  getLastPartOfURL(url) {
    return url.match(/\/([^\/]*)$/)[1];
  }

  b64toBlob(base64) {
    return fetch(base64).then((res) => res.blob());
  }

  checkUserRoles(user: User, ...roles: Role[]) {
    return roles.includes(user.role);
  }

  listUserRoles(user: User, roles: Role[]) {
    if (user.role === Role.ADMIN) {
      return roles.filter((role) => role !== Role.CONTROL);
    }
    return roles;
  }

  getLabelTitle(labels, id: number) {
    if (labels && labels.length > 0) {
      if (id == null) {
        return;
      }
      return labels.find((i) => i.id === id)?.title;
    }
    return;
  }

  checkPermission(userPermissions: UserPermissionInterface[], user: User, permission: Permission) {
    if (user != null) {
      switch (permission) {
        case Permission.CREATE_SLICKS:
          if (userPermissions != null && userPermissions.length > 0) {
            for (const ele of userPermissions) {
              if (user.id === ele.user_id) {
                return ele.permissions.includes(permission);
              }
            }
          }
      }
    }
    return false;
  }

  getSlickUserDetails(users, user: User): User {
    if (users && users.length > 0) {
      if (!user || !user.id) {
        return;
      }
      return users.find((i) => i.id === user.id);
    }
    return;
  }
}
