import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestComponent } from './test.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, TestComponent],
})
export class TestModule {
  constructor() {
    console.log('TestModule loaded.');
  }
}
