import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { setInterval as wSetInterval, clearInterval as wClearInterval } from 'worker-timers';
import { SelectCameraInputButtonComponent } from '../shared/new-slick/select-camera-input-button/select-camera-input-button.component';
import { fabric } from 'fabric'; // browser

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [CommonModule, SelectCameraInputButtonComponent],
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {
  @ViewChild('recordedVideo')
  recordVideoElementRef!: ElementRef;
  @ViewChild('testVideoInput') testVideoInput: ElementRef<HTMLVideoElement>;
  @ViewChild('previewCanvas') previewCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  recordVideoElement!: HTMLVideoElement;
  isRecording: boolean = false;
  haveCanvas: boolean = false;
  haveFabricCanvas: boolean = false;
  videoStream!: MediaStream;
  audioStream!: MediaStream;
  combinedStream: MediaStream;
  recordedBlobs!: Blob[];
  mediaRecorder: any;
  downloadUrl!: string;
  fabricCanvas: fabric.Canvas;
  recordingCanvas2dCtx: CanvasRenderingContext2D;

  videoConstraints = {
    video: { width: 1280, height: 720 },
  };
  audioConstraints = {
    audio: { echoCancellation: true },
  };

  rawFrames = 0;
  workerFrames = 0;
  renderFrames = 0;
  canvasRefreshFrame = 0;
  fabricCanvasRefreshFrame = 0;
  lastRawTime = performance.now();
  lastWorkerTime = performance.now();
  lastRenderTime = performance.now();
  lastCanvasRenderTime = performance.now();
  lastFabricCanvasRenderTime = performance.now();
  rawFps = 0;
  workerFps = 0;
  renderFps = 0;
  targetFps: number = 30;
  recorderLoopInterval: number;
  canvasRefreshFps = 0;
  fabricCanvasRefreshFps = 0;

  frameCount = 0;
  startTime = Date.now();

  cameraDeviceId: string = '';
  canvasContext!: CanvasRenderingContext2D;

  canvasRefreshRate: number = 50;
  fabricCanvasRefreshRate: number = 50;

  constructor() {}

  async ngOnInit() {}

  async ngOnDestroy() {
    this.stopTracks();
  }

  drawToCanvas() {
    if (this.canvasContext) {
      this.canvasContext.drawImage(this.testVideoInput.nativeElement, 0, 0, 640, 360);
    } else {
      console.log('no canvasContext');
    }
  }

  canvasTimerCallback() {
    if (this.testVideoInput.nativeElement.paused || this.testVideoInput.nativeElement.ended || !this.haveCanvas) {
      console.log('paused or ended:', this.haveCanvas);
      return;
    }
    this.drawToCanvas();
    this.calculateCanvasFrameRate();
    setTimeout(() => {
      this.canvasTimerCallback();
    }, this.canvasRefreshRate);
  }

  updateCanvasRefreshRate(event: any) {
    this.canvasRefreshRate = event.target.value;
  }

  updateFabricCanvasRefreshRate(event: any) {
    this.fabricCanvasRefreshRate = event.target.value;
  }

  fabricCanvasTimerCallback() {
    if (this.testVideoInput.nativeElement.paused || this.testVideoInput.nativeElement.ended || !this.haveFabricCanvas) {
      return;
    }
    this.drawToFabricCanvas();
    this.calculateFabricCanvasFrameRate();
    setTimeout(() => {
      this.fabricCanvasTimerCallback();
    }, this.fabricCanvasRefreshRate);
  }

  addCanvas() {
    if (this.testVideoInput.nativeElement.paused || this.testVideoInput.nativeElement.ended) {
      return;
    }
    this.haveCanvas = !this.haveCanvas;
    this.createCanvas();
    this.canvasTimerCallback();
  }

  async addFabricCanvas() {
    if (this.testVideoInput.nativeElement.paused || this.testVideoInput.nativeElement.ended) {
      return;
    }
    this.haveFabricCanvas = !this.haveFabricCanvas;
    this.createFabricCanvas();
    this.fabricCanvasTimerCallback();
    //requestAnimationFrame(this.drawToFabricCanvas);
  }

  async createCanvas() {
    this.canvasContext = this.canvas.nativeElement.getContext('2d');
    this.canvasContext.drawImage(this.testVideoInput.nativeElement, 0, 0, 640, 360);
  }

  drawToFabricCanvas() {
    const fabricObject = new fabric.Image(this.canvas.nativeElement, {
      backgroundColor: 'white',
      left: 0, // 0
      top: 0, // 0
      height: 360, // 1080
      width: 640, // 1920
      selectable: false,
    });
    this.fabricCanvas.add(fabricObject);
    this.fabricCanvas.bringToFront(fabricObject);
  }

  createFabricCanvas() {
    this.fabricCanvas = new fabric.Canvas(this.previewCanvas.nativeElement);
  }

  removeFabricCanvas() {
    this.haveFabricCanvas = !this.haveFabricCanvas;
    this.fabricCanvas.dispose();
  }

  async removeCanvas() {
    this.haveCanvas = !this.haveCanvas;
    this.canvasRefreshFps = 0;
    this.clearCanvas();
  }

  clearCanvas() {
    this.canvasContext.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    return;
  }

  clearFabricCanvas() {
    this.previewCanvas.nativeElement
      .getContext('2d')
      .clearRect(0, 0, this.previewCanvas.nativeElement.width, this.previewCanvas.nativeElement.height);
    return;
  }

  stopTracks() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (this.combinedStream) {
      this.combinedStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  async startVideo() {
    this.getVideoStream();
    this.getAudioStream();
  }

  async getVideoStream() {
    const constraints: MediaStreamConstraints = {
      audio: false,
      video: {
        deviceId: this.cameraDeviceId,
        width: 640,
        height: 360,
      },
    };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      this.videoStream = stream;
      this.recordVideoElement = this.recordVideoElementRef.nativeElement;
      this.testVideoInput.nativeElement.srcObject = this.videoStream;
    });
  }

  calculateCanvasFrameRate() {
    this.canvasRefreshFrame++;
    const now = performance.now();
    const elapsed = now - this.lastCanvasRenderTime;
    if (elapsed >= 1000) {
      this.canvasRefreshFps = Math.round((this.canvasRefreshFrame * 1000) / elapsed);
      this.lastCanvasRenderTime = now;
      this.canvasRefreshFrame = 0;
    }
  }

  calculateFabricCanvasFrameRate() {
    this.fabricCanvasRefreshFrame++;
    const now = performance.now();
    const elapsed = now - this.lastFabricCanvasRenderTime;
    if (elapsed >= 1000) {
      this.fabricCanvasRefreshFps = Math.round((this.fabricCanvasRefreshFrame * 1000) / elapsed);
      this.lastFabricCanvasRenderTime = now;
      this.fabricCanvasRefreshFrame = 0;
    }
  }

  async getAudioStream() {
    navigator.mediaDevices.getUserMedia(this.audioConstraints).then((stream) => {
      this.audioStream = stream;
    });
  }

  // Combine video and audio tracks into a single MediaStream
  async combineStreams() {
    this.combinedStream = new MediaStream([
      ...this.videoStream.getVideoTracks(), // Add video tracks
      ...this.audioStream.getAudioTracks(), // Add audio tracks
    ]);
  }

  async startRecording() {
    this.recordedBlobs = [];
    const options: any = { mimeType: 'video/webm' };

    try {
      await this.combineStreams();
      this.mediaRecorder = new MediaRecorder(this.combinedStream, options);
    } catch (err) {
      console.log(err);
    }

    this.mediaRecorder.start(); // collect 100ms of data
    this.isRecording = !this.isRecording;
    this.onDataAvailableEvent();
    this.onStopRecordingEvent();
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.isRecording = !this.isRecording;
    console.log('Recorded Blobs:', this.recordedBlobs);
  }

  reset() {
    if (this.recordVideoElement) {
      this.recordVideoElement.src = '';
      this.recordVideoElement.srcObject = null;
    }
    this.stopTracks();
    this.isRecording = false;
    this.recordedBlobs = [];
    this.downloadUrl = '';
    this.testVideoInput.nativeElement.src = '';
    this.testVideoInput.nativeElement.srcObject = null;
    this.removeCanvas();
    this.removeFabricCanvas();
    this.canvasRefreshFps = 0;
    this.fabricCanvasRefreshFps = 0;
    this.canvasRefreshFrame = 0;
    this.fabricCanvasRefreshFrame = 0;
  }

  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = () => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: 'video/webm',
        });
        this.downloadUrl = window.URL.createObjectURL(videoBuffer); // you can download with <a> tag
        this.recordVideoElement.src = this.downloadUrl;
      };
    } catch (error) {
      console.log(error);
    }
  }

  async updateDeviceId(device: MediaDeviceInfo) {
    this.cameraDeviceId = device.deviceId;
  }

  delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
