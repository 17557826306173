<div class="flex w-full flex-col p-4">
  <div class="flex flex-row justify-between">
    <div class="flex flex-col justify-center">
      <div class="pl-2 text-xl">Test Video</div>
      <div class="flex flex-row justify-center">
        <button class="btn btn-primary btn-lg m-2 rounded-xl bg-slate-400 p-2" (click)="startVideo()">
          Start Video/Audio
        </button>
        <button
          class="btn btn-primary btn-lg m-2 rounded-xl bg-slate-400 p-2"
          *ngIf="!isRecording"
          (click)="startRecording()"
        >
          Start Recording
        </button>
        <button
          class="btn btn-warning btn-lg m-2 rounded-xl bg-slate-400 p-2"
          *ngIf="isRecording"
          (click)="stopRecording()"
        >
          Stop Recording
        </button>
        <button
          class="btn btn-warning btn-lg m-2 rounded-xl bg-slate-400 p-2"
          *ngIf="!haveCanvas"
          (click)="addCanvas()"
        >
          Add Canvas
        </button>
        <button
          class="btn btn-warning btn-lg m-2 rounded-xl bg-slate-400 p-2"
          *ngIf="haveCanvas"
          (click)="removeCanvas()"
        >
          Remove Canvas
        </button>
        <button
          class="btn btn-warning btn-lg m-2 rounded-xl bg-slate-400 p-2"
          *ngIf="!haveFabricCanvas"
          (click)="addFabricCanvas()"
        >
          Add Fabric Canvas
        </button>
        <button
          class="btn btn-warning btn-lg m-2 rounded-xl bg-slate-400 p-2"
          *ngIf="haveFabricCanvas"
          (click)="removeFabricCanvas()"
        >
          Remove Fabric Canvas
        </button>
        <button class="btn btn-warning btn-lg m-2 rounded-xl bg-slate-400 p-2" (click)="reset()">Reset</button>
      </div>
      <div class="flex flex-row gap-2">
        <app-select-camera-input-button (selection)="updateDeviceId($event)" />
        <div class="flex flex-col gap-2">
          <div class="mt-2 flex items-center gap-2">
            <span>Canvas Refresh Rate</span>
            <input
              type="range"
              [value]="canvasRefreshRate"
              min="50"
              max="500"
              step="50"
              (input)="updateCanvasRefreshRate($event)"
              class="w-24"
            />
            <span>{{ canvasRefreshRate }}</span>
          </div>
          <div class="mt-2 flex items-center gap-2">
            <span>Fabric Canvas Refresh Rate</span>
            <input
              type="range"
              [value]="fabricCanvasRefreshRate"
              min="50"
              max="500"
              step="50"
              (input)="updateFabricCanvasRefreshRate($event)"
              class="w-24"
            />
            <span>{{ fabricCanvasRefreshRate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="bg-black flex flex-col gap-1 rounded bg-opacity-50 px-2 py-1">
        <div>{{ this.canvasRefreshFps }} FPS Canvas Refresh</div>
        <div>{{ this.fabricCanvasRefreshFps }} FPS Fabric Canvas Refresh</div>
        <!--<div>{{ this.renderFps }} FPS final render</div>-->
        <!--<div class="mt-2 flex items-center gap-2">
          <span>Target FPS:</span>
          <input
            type="range"
            [value]="targetFps"
            min="10"
            max="120"
            step="1"
            (input)="updateFps($event)"
            class="w-24"
          />
          <span>{{ targetFps }}</span>
        </div>-->
      </div>
    </div>
  </div>
  <div class="flex flex-row justify-between pt-10">
    <div class="flex flex-row rounded-lg border-2">
      <div class="m-4 flex flex-col text-center">
        <h1>Raw Video Stream</h1>
        <video width="640" height="360" class="video" #testVideoInput autoplay controls></video>
      </div>
    </div>
    <div class="flex flex-row rounded-lg border-2">
      <div class="m-4 flex flex-col text-center">
        <h1>Video Recording</h1>
        <video width="640" height="360" class="video" style="width: 360 !important" controls #recordedVideo></video>
      </div>
    </div>
  </div>
  <div class="flex flex-row justify-between pt-10">
    <div class="flex flex-row rounded-lg border-2">
      <div class="m-4 flex flex-col text-center">
        <h1>Canvas</h1>
        <canvas #canvas width="640" height="360" class=""></canvas>
      </div>
    </div>
    <div class="flex flex-row rounded-lg border-2">
      <div class="m-4 flex flex-col text-center">
        <h1>Fabric Canvas</h1>
        <canvas #previewCanvas width="640" height="360" class=""></canvas>
      </div>
    </div>
  </div>
</div>
