<button
  [disabled]="this.disabled"
  [matMenuTriggerFor]="cameraSelectMenu"
  matTooltip="Choose your camera"
  class="!rounded-none !border-l-0 !border-r-0 !bg-white !px-8 !py-[2.875rem] !shadow-none"
  mat-stroked-button
>
  <span class="flex flex-col gap-1">
    <div class="m-auto flex flex-row items-center">
      <mat-icon class="text-black-300">videocam</mat-icon>
      <mat-icon class="text-black-300">keyboard_arrow_up</mat-icon>
    </div>
    <span class="!inline-block font-semibold text-black-300">Camera</span>
  </span>
</button>

<mat-menu #cameraSelectMenu xPosition="after" yPosition="above">
  @for (item of cameraOptions; track $index) {
    <button (click)="emitSelection(item)" class="!rounded-none" mat-menu-item>
      <span [ngClass]="{ underline: item.selected }">{{ item.media.label | cleanDeviceLabel }}</span>
    </button>
  }
  <hr class="border-[#EEEEF0]" />
  <button (click)="emitSelection(null)" class="!rounded-none" mat-menu-item>
    <span [ngClass]="{ underline: false }" class="text-[#BA1A1A]">Disable camera</span>
  </button>
</mat-menu>
