import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { CleanDeviceLabelPipe } from '../../../_pipes/clean-device-label.pipe';

type MediaDeviceOption = { media: MediaDeviceInfo; selected: boolean };

@Component({
  selector: 'app-select-camera-input-button',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, MatIconModule, MatTooltip, CleanDeviceLabelPipe],
  templateUrl: './select-camera-input-button.component.html',
  styleUrl: './select-camera-input-button.component.css',
})
export class SelectCameraInputButtonComponent {
  async ngOnInit(): Promise<void> {
    navigator.mediaDevices.ondevicechange = async () => {
      this.updateDeviceList();
    };
    this.updateDeviceList(true);
  }
  cameraOptions: MediaDeviceOption[] = [];
  @Input() disabled = false;

  @Output()
  selection = new EventEmitter<MediaDeviceInfo | null>();
  public selectedDevice: MediaDeviceInfo | null = null;

  public async updateDeviceList(selectDefault: boolean = false) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.cameraOptions = transform(devices.filter((element) => element.kind === 'videoinput'));
    if (selectDefault) {
      // take default
      this.emitDefaultDevice();
    }
  }

  public emitDefaultDevice() {
    this.emitSelection(this.cameraOptions[0]);
  }

  emitSelection(device: MediaDeviceOption) {
    this.cameraOptions.forEach((d) => (d.selected = false));
    if (device) {
      this.selection.emit(device.media);
      device.selected = true;
      this.selectedDevice = device.media;
    } else {
      this.selection.emit(null);
      this.selectedDevice = null;
    }
  }
}
function transform(devices: MediaDeviceInfo[]) {
  if (devices) {
    return devices.map((d) => ({ media: d, selected: false }));
  }
  return null;
}
