import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanDeviceLabel',
  standalone: true,
})
export class CleanDeviceLabelPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const re = new RegExp('^([a-zA-Z0-9]{4}:[a-zA-Z0-9]{4}[\\W])$');
    const pos = value.split('(');

    if (pos.length > 0 && re.test(pos[1])) {
      return pos[0].trim();
    }
    return value;
  }
}
